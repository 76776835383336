import React, { useEffect, useState, lazy,Suspense } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { MiContextoProvider } from "./contexto";
import { createTheme, ThemeProvider } from "@mui/material";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import CssBaseline from '@mui/material/CssBaseline';
import "./recursos/Semantic-UI-CSS-master/semantic.min.css"
import authService from "./servicios/auth.service";
import Home from "./paginas/home";
import Login from "./paginas/Login";
import "./App.css";
import { styled } from "@mui/system";
const imageURL =
    "https://cdn.pixabay.com/photo/2023/05/20/20/39/european-roller-8007340__340.jpg";
const Background = styled("div")({
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundImage: `url(${imageURL})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
});
const theme = createTheme({
  palette: {
    primary: {
      // main: "#C0DB1A",
      main: "#71c160",
    },
    secondary: {
      // main: "#B3B8BB",
      main: "#BCBDC0",
    },
    background: {
      secondary: "rgba(188, 189, 192, 0.8)" // Definir en background para más flexibilidad
    }
  },
  components: {
    MuiGrid: {
      styleOverrides: {
        root: {
          paddingTop: '2rem',
          // Aplica paddingTop solo para xs
          '@media (max-width:600px)': {
            paddingTop: '1rem', // Aplica el paddingTop con !important solo en pantallas xs
            paddingLeft: 0,
            paddingRight: 0,
          },
        },
      },
    },
  },

});
function App() {

  const location = useLocation();
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState(undefined);
  const [barra, setBarra] = useState(false);
  let usuario = {};
  authService.comprobarToken();
  useEffect(
    (props) => {
      usuario = authService.getCurrentUser();
      if (location.pathname !=="/login") {
        setBarra(true);
      } else {
        setBarra(false);
      }

      setCurrentUser(usuario);
      if (usuario) {
        console.log("hay usuario", usuario);
        // setCurrentUser(usuario);
        console.log("currentUser", currentUser);
        // navigate("/login");
        if (authService.comprobarToken()) {
          console.log("Token expirado o no hay token");
          if (location.pathname != "login") {
            navigate("/login");
          }
        }
      } else {
        console.log("no hay usuario");
        if (location.pathname != "login") {
          navigate("/login");
        }
      }
    },
    [location.pathname]
  );

  const logOut = () => {
    authService.logout();
  };

  return (

          <ThemeProvider theme={theme}>
              <CssBaseline />
              {/* {barra ? <Navbar2024></Navbar2024> : <></>} */}
              {/* <Router> */}
              <Suspense fallback={<div>Loading...</div>}>
                  <Routes>
                      <Route exact path={"/"} element={<Home />} />
                      <Route
                          path={"/login"}
                          element={<Login />}
                      />
                      
                  </Routes>
              </Suspense>
              {/* </Router> */}
          </ThemeProvider>
  
  );
}
export default App;
