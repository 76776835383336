import AxiosApi from './axios.service'
const API_URL = "https://api.fichajedigital.com/api/";
export default  (idusuario, tipo, pin,sede)=>{
    return new Promise ((resolve,reject)=>{
        let data ={
            idUsuario:idusuario,
            tipo:tipo,
            codigoPin:pin,
            id_empresaSede:sede
        }
        AxiosApi.axiosApi
        .post(API_URL+'panel/fichaje',data)
        .then((response) => {
    
            console.log(response);
            if (response.data){
              resolve(response.data)
            } 
            if(response.name && response.name=='AxiosError'){
                reject (response)
            }
            
            
    
          
        })
        .catch((error) => {
           
            console.log(error.response);
            reject(error)
        });

    })
}